<template>
  <header id="home" class="header-area pt-50">
      <div class="navigation-bar">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <nav class="navbar navbar-expand-lg">
                <a class="navbar-brand" href="/">
                  <img src="/assets/images/logo.png" alt="Logo" />
                </a>
                <button
                  class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="toggler-icon"></span>
                  <span class="toggler-icon"></span>
                  <span class="toggler-icon"></span>
                </button>

                <div
                  class="collapse navbar-collapse"
                  id="navbarSupportedContent"
                  
                >
                  <ul id="nav" class="navbar-nav ml-auto" v-if="$route.name === 'Home'">
                    <li class="nav-item active">
                      <a class="page-scroll" href="#home">Home</a>
                    </li>
                    <li class="nav-item">
                      <a class="page-scroll" href="#about">About</a>
                    </li>
                    <li class="nav-item">
                      <a class="page-scroll" href="#service">Clients</a>
                    </li>
                    <li class="nav-item">
                      <a class="page-scroll" href="#team">Team</a>
                    </li>
                  </ul>
                  <!-- navbar nav -->
                </div>
                <div class="navbar-btn ml-20 d-none d-sm-block">
                  <a class="main-btn" href="tel:01313806000"
                    ><i class="lni-phone"></i> 0131 380 6000</a
                  >
                </div>
              </nav>
              <!-- navbar -->
            </div>
          </div>
          <!-- row -->
        </div>
        <!-- container -->
      </div>
      <br/>
      <br/>
      <!-- navigation bar -->
    </header>
    <br />
    <br />
  <router-view></router-view>
  <footer id="footer" class="footer-area">
      <div class="footer-widget pt-10 pb-40">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-8">
              <div class="footer-logo mt-50">
                <ul class="footer-info">
                  <li>
                    <div class="single-info">
                      <div class="info-icon">
                        <i class="lni-phone-handset"></i>
                      </div>
                      <div class="info-content">
                        <p>0131 380 6000</p>
                      </div>
                    </div>
                    <!-- single info -->
                  </li>
                  <li>
                    <div class="single-info">
                      <div class="info-icon">
                        <i class="lni-envelope"></i>
                      </div>
                      <div class="info-content">
                        <p>hello@vwtalent.com</p>
                      </div>
                    </div>
                    <!-- single info -->
                  </li>
                  <li>
                    <div class="single-info">
                      <div class="info-icon">
                        <i class="lni-map"></i>
                      </div>
                      <div class="info-content">
                        <p>9-10 St Andrew Square, Edinburgh, EH2 2AF</p>
                      </div>
                    </div>
                    <!-- single info -->
                  </li>
                </ul>
                <ul class="footer-social mt-20">
                  <li>
                    <a rel="noopener" target="_blank" href="https://www.facebook.com/vwtalent"
                      ><i class="lni-facebook-filled"></i
                    ></a>
                  </li>
                  <li>
                    <a rel="noopener" target="_blank" href="https://twitter.com/VWTalent"
                      ><i class="lni-twitter-original"></i
                    ></a>
                  </li>
                  <li>
                    <a href="#"><i class="lni-google"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="lni-instagram"></i></a>
                  </li>
                </ul>
              </div>
              <!-- footer logo -->
            </div>
            <div class="col-lg-9 col-md-8 col-sm-6">
              <div class="mt-200">
                Vella Wozniak LLP is a Limited Liability Partnership registered
                at 71-75 Shelton Street, Covent Garden, London WC2H 9JQ (Company
                number OC441552).
              </div>
            </div>
          </div>
          <!-- row -->
        </div>
        <!-- container -->
      </div>
      <!-- footer widget -->
    </footer>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    console.log(this.$route)
  }
}
</script>