<template>
     <section id="profile" class="project-area pt-125 pb-130">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <img src="../../assets/images/team/HVT.jpg" />
                </div>
                <div class="col-lg-8">
                    <h2>Harry Vella-Thompson</h2>
                    <h4>Managing Partner</h4><br/>
                    <h5>Email: <a href="mailto:harry@vwtalent.com">harry@vwtalent.com</a></h5>
                    <br>
                    <p>Having worked in the arts and entertainment business since the age of 16, Harry decided to start a talent agency
                       with the goal of representing and growing the careers of talent in the UK, with an emphasis on diversity.
                    </p>
                    <br>
                    <p>
                        After leaving the Royal Northern College of Music, Harry set out on a career in music and theatre, particularly in
                        the musical theatre scene. After producing a few shows in his hometown of Manchester and taking work up to the Edinburgh Festival Fringe and recognising he wanted to do more for industry professionals, Harry had a rethink about the role he could play.
                    </p>
                    <br>
                    <p>
                        Harry is also well-versed in entertainment contracts and is the go-to-guy within <i>Vella Wozniak</i> for advice on contract
                        negotiations.
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>