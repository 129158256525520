<template>
    <section id="profile" class="project-area pt-125 pb-130">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <img :src="`https://storage.googleapis.com/${process.env.VUE_APP_BUCKET}/${profilePic}`" />
                </div>
                <div class="col-lg-8">
                    <h2>{{ name }}</h2>
                    <h4>{{ position }}</h4><br/>
                    <h5>Email: <a :href="'mailto:' + email">{{ email }}</a></h5>
                    <br>
                    <span>
                        {{ bio }}
                    </span>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        profilePic: String,
        name: String,
        position: String,
        email: String,
        bio: String,
    }
}
</script>