<template>
    <div class="col-lg-3 col-md-6 col-sm-6">
        <div
            class="single-team text-center mt-30 wow fadeInUp"
            data-wow-duration="1.5s"
            data-wow-delay="0.4s"
        >
            <div class="team-image">
            <img :src="headshotImg" class="headshot" :alt="name" />
            </div>
            <div class="team-content">
            <h4 class="team-name">
                <a v-if="client.spotlightPIN" rel="noopener" :href="'https://www.spotlight.com/' + client.spotlightPIN" target="_blank"
                >{{ client.stageName }}</a
                >
                <div v-else>{{ client.stageName }}</div>
            </h4>
            <span class="sub-title rep-name">{{ client.town }}</span><br>
            <span class="sub-title rep-name">Rep: <a :href="`mailto: ${client.agentName.split(' ')[0].toLowerCase()}@vwtalent.com`">{{ client.agentName }}</a></span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        client: Object,

    },
    data() {
        return {
            agent: {},
        }
    },
    computed: {
        headshotImg() {
            return `${process.env.VUE_APP_CDN}${process.env.VUE_APP_BUCKET}/headshots/${this.client.id}`;
        }
    }
}
</script>