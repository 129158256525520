<template>
     <section id="profile" class="project-area pt-125 pb-130">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <img src="../../assets/images/team/GW.jpg" />
                </div>
                <div class="col-lg-8">
                    <h2>Glynis Wozniak</h2>
                    <h4>Senior Partner</h4><br/>
                    <h5>Email: <a href="mailto:glynis@vwtalent.com">glynis@vwtalent.com</a></h5>
                    <br>
                    <p>
                        Beginning life as a ballet dancer, upon returning to the UK after 16 years in Asia, Glynis was invited to be in a play. She went on to study Acting and Performance at Inverness College before graduating in 2009 with a BA(Hons) Performance from the University of the West of Scotland.
                        <br>
                        <br>
                        Glynis remains a working actor and also runs Shine Bright Entertainments specialising in solo shows for community venues.
                        <br>
                        <br>
                        As a life coach trained in multiple transformation modalities, she is particularly interested in encouraging people to find their magic within and utiliing performing arts for raising self esteem to help you 'shine bright.'
                        Two years ago, she trained to be an independent civil celebrant for funerals to add Shine Bright Celebrant to her toolbox.   
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>