<template>
    <section id="profile" class="project-area">
        <br />
        <br />
        <div class="container">
            <div class="row">
                <h2>{{title}}</h2>
            </div>
            <div class="row">
                <div v-if="locked">
                    <br/>
                    <h3>This page is currently locked. If you would like access please reach out to a member of the team who will be able to assist you.</h3>
                    <br/>
                    <input type="password" placeholder="Password" v-model="password" @input="testPassword()" />
                </div>
                <ClientCard v-else v-for="client in clients" :key="client.spotlightPIN" :client="client"></ClientCard>
            </div>
        </div>
    </section>
</template>
<script>
import ClientCard from '../components/ClientCard.vue'

export default {
    components: {
        ClientCard,
    },
    props: {
        type: String,
    },
    data() {
        return {
            clients: [],
            clientTypes: {
                "actors": 0,
                "creatives": 1,
                "writers": 2,
                "young-performers": 3,
            },
            locked: false,
            password: "",
            agents: [],
        }
    },
    async mounted() {
        await fetch(`${process.env.VUE_APP_API}Staff`, {
            headers: {
                "AT": process.env.VUE_APP_TOKEN,
            }
        })
        .then((response) => response.json())
        .then((data) => this.agents = data);

        if (this.clientTypes[this.type] === 3) {
            this.locked = true;
        } else {
            await fetch(`${process.env.VUE_APP_API}Client/Type/${this.clientTypes[this.type]}/Display`, {
                headers: {
                "AT": process.env.VUE_APP_TOKEN,
                }
            })
            .then((response) => response.json())
            .then((data) => {
                this.clients = this.shuffle(data);
                for (var i = 0; i < this.clients.length; i++) {
                    const agent = this.agents.filter(agent => { return agent.id === this.clients[i].agentId })[0]
                    this.clients[i].agentName = `${agent.firstName} ${agent.lastName}`
                }
            });
        }
        },
    computed: {
        title() {
            var arr = this.type.split('');
            arr[0] = arr[0].toUpperCase();
            var t = arr.join('');

            if (t.includes('-')) {
                var arr2 = t.split('-');
                var arr3 = arr2[1].split('');
                arr3[0] = arr3[0].toUpperCase();

                return `${arr2[0]} ${arr3.join('')}`;
            } else {
                return t;
            }
            
        }
    },
    methods: {
        async testPassword() {
            if (this.password === "y0ungVWCl13nt5#") {
                this.locked = false;

                await fetch(`${process.env.VUE_APP_API}Client/Type/${this.clientTypes[this.type]}`, {
                headers: {
                "AT": process.env.VUE_APP_TOKEN,
                }
            })
            .then((response) => response.json())
            .then((data) => {
                this.clients = this.shuffle(data);
                for (var i = 0; i < this.clients.length; i++) {
                    const agent = this.agents.filter(agent => { return agent.id === this.clients[i].agentId })[0]
                    this.clients[i].agentName = `${agent.firstName} ${agent.lastName}`
                }
            });
            }
        },
        shuffle(array) {
            let currentIndex = array.length,  randomIndex;

            // While there remain elements to shuffle.
            while (currentIndex != 0) {

                // Pick a remaining element.
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }

            return array;
        }
    }
}
</script>