<template>
  <div>
    <div class="header-banner d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-9 col-sm-10">
            <div class="banner-content">
              <h4 class="sub-title wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="1s">
                Welcome
              </h4>
              <h1 class="banner-title mt-10 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="2s">
                <span>To</span> the Home of Talent
              </h1>
              <h5 class="mt-10 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="2s">
                Full-Service Talent Agency
              </h5>
            </div>
            <!-- banner content -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
      <div class="banner-image bg_cover" :style="`background-image: url(${bgImg})`"></div>
    </div>
    <!--====== ABOUT PART START ======-->

    <div id="about" class="about-area pt-80 pb-130">
      <div class="container">
        <div class="row">
          <div class="col-lg-3" style="max-width: 25%">
            <div class="about-image mt-50 clearfix">
                <Carousel :wrap-around="true" :autoplay="2000" :mouse-drag="false" dir="rtl">
                  <Slide v-for="partner in partnersTop" :key="partner">
                    <div class="carousel__item">
                      <img :src="`assets/images/partners/${partner}.png`" alt="Partner Logo" />
                    </div>
                  </Slide>
                </Carousel>
              </div>
          </div>
          <div class="col-lg-3"  style="max-width: 25%">
            <div class="about-image mt-200 clearfix">
              <Carousel :wrap-around="true" :autoplay="2000" :mouse-drag="false">
                  <Slide v-for="partner in partnersBtm" :key="partner">
                    <div class="carousel__item">
                      <img :src="`assets/images/partners/${partner}.png`" alt="Partner Logo" />
                    </div>
                  </Slide>
                </Carousel>
              </div>
          </div>
          <div class="col-lg-6" style="max-width: 50%;">
            <div class="about-content mt-45">
              <h4 class="about-welcome">About Us</h4>
              <h3 class="about-title mt-10">Who Are We?</h3>
              <p class="mt-25">
                <i>Vella Wozniak</i> is a dynamic talent agency representing clients from across the UK and Europe ranging from seasoned professionals to emerging artists.
                <br />
                <br />
                We work holistically and personally with our clients to help find work; guide and build careers; nurture your outlook and provide support on every level.
                <br />
                <br />
                Our main office is in Edinburgh, and we have a great deal of in-house knowledge on contracts, union regulations, employment rights, best practices, and a positive mindset to help deliver the best results.
              </p>
            </div>
            <!-- about content -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </div>

    <!--====== ABOUT PART ENDS ======-->

    <div id="service" class="services-area pt-125 pb-130 gray-bg">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="section-title text-center pb-20">
              <h5 class="sub-title mb-15">Our Clients</h5>
              <h2 class="title">Who Do We Represent?</h2>
            </div>
            <!-- section title -->
          </div>
        </div>
        <!-- row -->
        <div class="row justify-content-center">
          <div class="col-lg-3 col-md-6 col-md-8">
            <div class="single-services text-center mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.4s">
              <a href="./clients/actors">
                <span class="link"></span>
                <div class="services-content">
                  <h4 class="services-title">Actors</h4>
                </div>
              </a>
            </div>
            <!-- single services -->
          </div>
          <div class="col-lg-3 col-md-6 col-md-8">
            <div class="single-services text-center mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.8s">
              <a href="./clients/creatives">
                <span class="link"></span>
                <div class="services-content">
                  <h4 class="services-title">Creatives</h4>
                </div>
              </a>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-md-8">
            <div class="single-services text-center mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="1.0s">
              <a href="./clients/writers">
                <span class="link"></span>
                <div class="services-content">
                  <h4 class="services-title">Writers</h4>
                </div>
              </a>
            </div>
            <!-- single services -->
          </div>
          <div class="col-lg-3 col-md-6 col-md-8">
            <div class="single-services text-center mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="1.2s">
              <a href="./clients/young-performers">
                <span class="link"></span>
                <div class="services-content">
                  <h4 class="services-title">Young Performers</h4>
                </div>
              </a>
            </div>
            <!-- single services -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </div>
    <!--====== TEAM PART START ======-->

    <div id="team" class="team-area pt-125 pb-130 gray-bg">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="section-title text-center pb-20">
              <h5 class="sub-title mb-15">Meet The Team</h5>
              <h2 class="title">Our Agents</h2>
            </div>
            <!-- section title -->
          </div>
        </div>
        <!-- row -->
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-team text-center mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.4s">
              <div class="team-image">
                <img src="assets/images/team/HVT.jpg" alt="Harry Vella-Thompson" />
              </div>
              <div class="team-content">
                <h4 class="team-name">
                  <a href="/staff/harry-vellathompson">Harry Vella-Thompson</a>
                </h4>
                <span class="sub-title">Managing Partner</span>
                <br />
                <span class="social">Actors/Writers/Directors</span>
              </div>
            </div>
            <!-- single team -->
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-team text-center mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.8s">
              <div class="team-image">
                <img src="assets/images/team/GW.jpg" alt="Glynis Wozniak" />
              </div>
              <div class="team-content">
                <h4 class="team-name">
                  <a href="/staff/glynis-wozniak">Glynis Wozniak</a>
                </h4>
                <span class="sub-title">Senior Partner</span>
                <br />
                <span class="social">Actors</span>
              </div>
            </div>
            <!-- single team -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </div>

    <!--====== TEAM PART ENDS ======-->
    <section id="map" class="map-area">
      <div class="mapouter">
        <div class="gmap_canvas">
          <iframe id="gmap_canvas"
            src="https://maps.google.com/maps?q=9-10+St+Andrew Square+Edinburgh+EH2+2AF&t=&z=13&ie=UTF8&iwloc=&output=embed"
            frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { Carousel, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

export default {
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      partnersTop: ["BBC", "HLG", "MCS", "NLP", "RSC", "C4"],
      partnersBtm: ["AEDDB", "AST", "BTH", "ITV", "MBC", "NGA"]
    }
  },
  computed: {
    bgImg() {
      return 'assets/images/banner/banner-image.png'
    }
  },
  methods: {
    shuffle(array) {
        let currentIndex = array.length,  randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex != 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }

        return array;
    }
  }
}
</script>
<style>
.carousel__item {
  min-height: 200px;
  width: 100%;
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
</style>