<template>
    <div>
        <StaffProfile :profilePic="staff.profilePic" :name="staff.firstName + ' ' + staff.lastName" :position="''" :email="staff.username" :bio="''"></StaffProfile>
    </div>
</template>
<script>
import StaffProfile from '@/components/StaffProfile.vue';

export default { 
    components: {
        StaffProfile,
    },
    props: {
        id: String,
    },
    data() {
        return {
            staff: {}
        }
    },
    async mounted() {
        await fetch(`${process.env.VUE_APP_API}/Staff/${this.id}`)
        .then((res) => res.json())
        .then((d) => this.staff = d);
    }
}
</script>