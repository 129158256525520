import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'

import HomePage from './pages/HomePage.vue'
import ClientPage from './pages/ClientPage.vue'
import StaffPage from './pages/StaffPage.vue'
import HvtProfile from './pages/agents/HvtProfile.vue'
import GwoProfile from './pages/agents/GwoProfile.vue'

const routes = [
    { path: '/', component: HomePage, name: 'Home' },
    { path: '/clients/:type', component: ClientPage, props: true },
    { path: '/staff/:id', component: StaffPage, props: true },
    { path: '/staff/harry-vellathompson', component: HvtProfile },
    { path: '/staff/glynis-wozniak', component: GwoProfile }
  ]

const router = createRouter({
    history: createWebHistory(),
    routes,
  })

createApp(App).use(router).mount('#app')
